import { setSessionItem } from "../services/sessionManagerService";

const SESSION_CONTEXT_CATEGORY = 'context_Category';
const SESSION_CONTEXT_PAGE = 'context_PageKey';
// const SESSION_CONTEXT_DATA = 'context_DATA';

// context event payload should contain the following elements
// payload  {
//       eventKey -- name of the event being triggered
//       etc...  any key value data that we want to pass 
//       for the event to use when consuming 
//  }
// 

export default function contextEventHandler(payload){
      
   if(payload){
      const {eventKey} = payload;
      switch(eventKey.toLowerCase()){
         case 'pageload':
            pageLoadEvent()
            break;
         default:
            break;
      }
   }
   else {
      // default assume that this is called on page load
      pageLoadEvent()
   }
   return true;
}

function pageLoadEvent(){
   const mappedData = getPageInformation();
   updatePageContext(mappedData);

}

// Update the Session Context page and Category 
function updatePageContext(mappedData){
   setSessionItem(SESSION_CONTEXT_CATEGORY, mappedData.categoryId);
   setSessionItem(SESSION_CONTEXT_PAGE, mappedData.pageKey);
}

// get the channelId and current page url from the windows object
function getPageInformation(){

   const locationData = readLocationData();
   const reportData = readReportingData();
   let mappedData = {};

   if((locationData.host).includes('onboarding')){
      // this is the apply flows
      mappedData =  getApplyMap(locationData, reportData)
   }
   else {
      const {channelId} = reportData;
      switch(channelId.toLowerCase()){
         case 'olb':
            mappedData = getOLBMap(locationData);         
            break;
         case 'dotcom':
            mappedData = getComMap(locationData);   
            break;
         default:
            mappedData =  getDefaultMap(locationData);   
      }   
   }
   return {
      ...mappedData,
      ...reportData
   };
}

function readReportingData(){
   const reportingData = window.reportingData;
   let channelId = 'OLB';
   let currentPageData = [];

   if(reportingData!=null){
      const {siteplatform, currentPage} = reportingData;
   
      if (siteplatform!=='' || siteplatform!==null){
         channelId = siteplatform;
      }    
      
      if(currentPage!=null){
         currentPageData = currentPage.split(':');
      }

   }
   return {
      channelId,
      currentPageData,
      rawReportData: reportingData
   };
}

function readLocationData(){
   const urlLocationData = window.location;
   const response = {};
  
   if(urlLocationData!=null){
      const {href,origin, host, pathname} = urlLocationData;
      const rawPath = href;
      
      response.origin = origin
      response.rawPath =  rawPath;
      response.pathname= pathname
      
      let  hostValue  =  host;
      const hostComps = hostValue.split('-');
      if(hostComps.length > 1){
         // if the path contains more than one item after the hyphen then strip off the hyphen
         // and return host as the rest of the path.
         hostValue = hostComps[1];

      }
      response.host = hostValue;
      
      const pathComps = pathname.split('/');
      const length = pathComps.length;
      if(length > 1){

         response.pathComponents = pathComps   
         
      }
   }

   return response;
}

function getDefaultMap(locationData){
   const {
      pathComponents
   } = locationData

   const length = pathComponents.length;
   let tempCategory = 'DEFAULT';

   // all mapped data is based off of the URL from the pageKey.
   let tempPageKey = pathComponents[length - 1].replace('.html','')
   if(tempPageKey !=""){
      // all mapped data is based off of the URL from the pageKey.
      switch (tempPageKey.toLowerCase()){
         default:
            const startIndex = findStartIndex(pathComponents);
            tempCategory = pathComponents[startIndex].toUpperCase();      
         break;
      }
   }else{
      tempPageKey = tempCategory;
   }

   return {
      categoryId: tempCategory,
      pageKey: tempPageKey
   }

}

function findStartIndex(pathComponents){
   const length = pathComponents.length;
   let startIndex = 0
   for(let index=0; index < length; index ++ ){
      if(pathComponents[index] !==''){
         startIndex = index;
         break;
      }
   }
   return startIndex;
}


function getOLBMap(locationData){

   // get default Mappings
   const {
      categoryId,
      pageKey
   } = getDefaultMap(locationData)

   let tempCategory =categoryId;
   let tempPageKey = pageKey;
   switch (tempPageKey.toLowerCase()){
      case 'kyc':
            tempCategory = 'DIGITALKYC';
            break;
      case 'balance-transfer':
         tempCategory = 'BALANCE_TRANSFER';
         break;   
      default:
         // for OLB set the category to the pageKey in all caps.
         tempCategory = tempPageKey.toUpperCase();
        break;
   }

   return {
      categoryId: tempCategory,
      pageKey: tempPageKey
   }
}

function getComMap(locationData){
   const {
      categoryId,
      pageKey
   } = getDefaultMap(locationData)

   let tempCategory =categoryId;
   let tempPageKey = pageKey;

   // this is for future case where mapping may be needed but should remain default for now.
   // switch (tempPageKey.toLowerCase()){
   //    default:
   //       tempCategory = tempPageKey.toUpperCase();      
   //      break;
   // }

   return {
      categoryId: tempCategory,
      pageKey: tempPageKey
   }

}


function getApplyMap(locationData, reportData){
  
   const {
      categoryId,
      pageKey
   } = getDefaultMap(locationData)
 
   const {
      // Configurator,
      applyPlatform,
      // bankerId,
      // channel,
      // currentPage,
      // pageName,
      // userAuthenticated
   } =  reportData

   const {
      pathComponents
   } = locationData;

    
   let tempCategory =categoryId;
   let tempPageKey = pageKey;

   if(applyPlatform !== undefined && applyPlatform !== null && applyPlatform !==''){
      let startIndex = findStartIndex(pathComponents);
      if (pathComponents[startIndex].toLowerCase() === 'usl'){
         const businessLine = pathComponents[startIndex + 1]; 
         switch (businessLine.toLowerCase()){
            case 'business':
               tempCategory = 'APPLY_BB_PSP_USBANK';
               break;
            default:
            break;
         }
      }
   }
   return {
      categoryId: tempCategory,
      pageKey: tempPageKey
   }


}
