import { authApiGateWayEndpoint } from "../constants/connections";
import { CONTACT_STATUS } from "../constants/global";
import { getCookieValue } from "../services/cookieManagerService";
import { getSessionItem } from "../services/sessionManagerService";
import {
  authChannelsProxy,
  CHAT_CONTACT_ID,
  SESSION_CHAT_DETAILS,
  SESSION_CUSTOMER_INFO,
  SESSION_METADATA,
  unAuthenticatedChannelsProxy,
} from "./constants";
import retrieveChatSession from "./retrieveChatSession";
import updateContact from "./updateContact";
//Can Resume Chat? if checkChatStatusAsync is true
export default function checkChatStatusAsync(
  customerDetails,
  metaDataDetails,
  isUpdateContact
) {
  const chatDetailsStrFromSession = getSessionItem(SESSION_CHAT_DETAILS);
  const customerInfoStrFromSession = getSessionItem(SESSION_CUSTOMER_INFO);
  const metaDataStrFromSession = getSessionItem(SESSION_METADATA);

  var useSessionStorageToResumeChat = true;
  if (metaDataStrFromSession && metaDataDetails) {
    // const { channelId } = JSON.parse(customerInfoStrFromSession);
    // const channelIdFromResume = customerDetails.channelId;
    // useSessionStorageToResumeChat = channelId == channelIdFromResume;
    const { apiGatewayEndpoint } = JSON.parse(metaDataStrFromSession);
    const apiGatewayEndpointFromResumeChat = metaDataDetails.apiGatewayEndpoint;

    const getUseSessionStorageToResumeChat = () =>
      (apiGatewayEndpoint.includes(authChannelsProxy) &&
        apiGatewayEndpointFromResumeChat.includes(authChannelsProxy)) ||
      (apiGatewayEndpoint.includes(unAuthenticatedChannelsProxy) &&
        apiGatewayEndpointFromResumeChat.includes(
          unAuthenticatedChannelsProxy
        ));

    useSessionStorageToResumeChat = getUseSessionStorageToResumeChat();
    // apiGatewayEndpoint === apiGatewayEndpointFromResumeChat;
  }

  //const Cookies2 = Cookies.noConflict();
  const trueValuePromise = new Promise((resolve, reject) => {
    resolve(true);
  });
  const falseValuePromise = new Promise((resolve, reject) => {
    resolve(false);
  });
  const currentChatSession = window.connect.currentChatSession;
  if (
    currentChatSession &&
    (currentChatSession.contactStatus === CONTACT_STATUS.DISCONNECTED ||
      currentChatSession.contactStatus === CONTACT_STATUS.ENDED)
  ) {
    // Usually when Agent or Customer ends or when connection couldn't be established.
    return falseValuePromise;
  } else if (
    useSessionStorageToResumeChat &&
    metaDataStrFromSession &&
    ((customerInfoStrFromSession && chatDetailsStrFromSession) ||
      (currentChatSession &&
        (currentChatSession.contactStatus === CONTACT_STATUS.CONNECTING ||
          currentChatSession.contactStatus === CONTACT_STATUS.CONNECTED)))
  ) {
    //When there is metadata and customerInfo and chatDetails
    //OR
    //When there is metadata and there is an active session object and valid status.
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  } else if (
    metaDataDetails &&
    metaDataDetails.apiGatewayEndpoint &&
    customerDetails &&
    customerDetails.oAuthToken &&
    customerDetails.partnerId &&
    getCookieValue(CHAT_CONTACT_ID)
  ) {
    const contactIdValue = getCookieValue(CHAT_CONTACT_ID);

    const sessionRestored = retrieveChatSession(
      customerDetails,
      metaDataDetails,
      contactIdValue,
      isUpdateContact
    );
    //sessionRestored is promise hence no need to return new promise
    return sessionRestored
      .then((restoredAllSessionItems) => {
        if (restoredAllSessionItems) {
          if (isUpdateContact) {
            updateContact(customerDetails, metaDataDetails, contactIdValue);
          }
          return trueValuePromise;
        } else return falseValuePromise;
      })
      .catch((error) => {
        console.error(
          "error while restoring chat session from persistence",
          error
        );
        return falseValuePromise;
      });
  } else {
    console.log(
      "IN ELSE---",
      metaDataStrFromSession,
      customerInfoStrFromSession,
      chatDetailsStrFromSession
    );
    return falseValuePromise;
  }
}
